<script>
  import Img from "./Img.svelte";

  export let data = {};
  export let onClick = null;
  export let onRemove = null;

  function handleClick() {
    if (onClick) {
      onClick(data);
    }
  }
  function handleRemove() {
    if (onRemove) {
      onRemove(data);
    }
  }
</script>

<style>
  .person-box {
    display: flex;
  }
</style>

<div class="person-box">
  <div class="m-r-4">
    <Img
      className="img-circle"
      personId={data.id ? data.id : ''}
      alt={data.name ? data.name : '[Tidak ada pegawai]'} />

  </div>
  <div>
    <div
      on:click={handleClick}
      class={`${onClick ? 'cursor-pointer blue-text' : ''}`}>
      <i class="fa fa-user" />
      {data.name ? data.name : '[Tidak ada pegawai]'} {' '}
      {#if onRemove}
        <span on:click={handleRemove} class="cursor-pointer red-text">
          <i class="fa fa-times" />
        </span>
      {/if}
    </div>
    <div>
      <i class="fa fa-id-card" />
      {data.no ? data.no : '[Tidak ada no induk]'}
    </div>
    {#if data.mobile}
      <div>
        <i class="fa fa-mobile" />
        {data.mobile ? data.mobile : '[Tidak ada no hp]'}
      </div>
    {/if}
  </div>
</div>
