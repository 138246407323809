<script>
  export let type = "text";
  export let defaultValue = "";
  export let selectOptions = [
    {
      key: "",
      name: "Silahkan Pilih"
    },
    {
      key: "m",
      name: "Pria"
    },
    {
      key: "f",
      name: "Wanita"
    }
  ];
  export let onChange = v => {
    console.log("advancedSearchInput", v);
  };
  const compareOptions = [
    {
      key: "eq",
      name: "="
    },
    {
      key: "lte",
      name: "<="
    },
    {
      key: "gte",
      name: ">="
    }
  ];
  let compare = defaultValue ? defaultValue.split("__")[0] : "eq";
</script>

<style>
  input[type="text"],
  input[type="number"] {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
    margin-bottom: 0px;
  }
  input[type="text"],
  input[type="number"] {
    display: inline-block;
    width: 235px;
    height: 23px;
  }
  select {
    display: inline-block;
    width: 247px;
    height: 35px;
  }
  .select-compare {
    width: 45px !important;
  }
  .number-compare {
    width: 187px !important;
  }

  /* DATE STYLE */
  .calendar-select-box {
    display: inline-block;
    border: 1px solid #f2f2f2;
    width: 100%;
    max-width: 247px;
    padding: 5px;
    border-radius: 5px;
  }
  .calendar-select {
    display: inline-block;
    position: relative;
    cursor: pointer !important;
  }
  .input-date {
    position: absolute;
    cursor: pointer !important;
    opacity: 0;
  }
  .input-date::-webkit-calendar-picker-indicator {
    cursor: pointer !important;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .input-date:hover + button {
    color: inherit !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
  }
  .calendar-btn {
    cursor: pointer !important;
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
  }

  /* DATE STYLE END */
</style>

{#if type === 'text'}
  <input
    on:change|preventDefault={e => {
      onChange(e.target.value);
    }}
    value={defaultValue}
    type="text" />
{/if}

{#if type === 'number'}
  <input
    on:change|preventDefault={e => {
      onChange(e.target.value);
    }}
    value={defaultValue}
    type="number" />
{/if}

{#if type === 'select'}
  <select
    on:change={e => {
      onChange(e.target.value);
    }}
    value={defaultValue}>
    {#each selectOptions as opt}
      <option value={opt.key}>{opt.name}</option>
    {/each}
  </select>
{/if}

{#if type === 'numberCompare'}
  <select
    on:change|preventDefault={e => {
      const numberValue = document.querySelector('.number-compare').value;
      onChange(`${e.target.value}__${numberValue}`);
    }}
    bind:value={compare}
    class="select-compare">
    {#each compareOptions as opt}
      <option value={opt.key}>{opt.name}</option>
    {/each}
  </select>
  <input
    on:change|preventDefault={e => {
      onChange(`${compare}__${e.target.value}`);
    }}
    value={defaultValue ? defaultValue.split('__')[1] : ''}
    class="number-compare"
    type="number" />
{/if}

{#if type === 'date'}
  <div class="calendar-select-box">
    <div class="calendar-select">

      <input
        id="datesBarInput"
        class="input-date"
        type="date"
        on:change={e => {
          const nextDateString = window
            .moment(e.target.value, 'YYYY-MM-DD')
            .format('YYYYMMDD');
          onChange(nextDateString);
        }}
        value={defaultValue ? window
              .moment(defaultValue, 'YYYYMMDD')
              .format('YYYY-MM-DD') : ''} />
      <button class="calendar-btn">
        <i class="fa fa-calendar" />
      </button>
      {defaultValue ? window
            .moment(defaultValue, 'YYYYMMDD')
            .format('DD MMM YYYY') : ''}
    </div>
  </div>
{/if}

{#if type === 'month'}
  <div class="calendar-select-box">
    <div class="calendar-select">

      <input
        id="datesBarInput"
        class="input-date"
        type="month"
        on:change={e => {
          const nextDateString = window
            .moment(e.target.value, 'YYYY-MM-DD')
            .format('YYYYMM');
          onChange(nextDateString);
        }}
        value={defaultValue ? window
              .moment(defaultValue, 'YYYYMM')
              .format('YYYY-MM-DD') : ''} />
      <button class="calendar-btn">
        <i class="fa fa-calendar" />
      </button>
      {defaultValue ? window
            .moment(defaultValue, 'YYYYMM')
            .format('MMM YYYY') : ''}
    </div>
  </div>
{/if}
