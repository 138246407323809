<script>
  import { onMount } from "svelte";
  import { user, logout } from "../../stores/auth/myUser.js";
  import {
    loading,
    error,
    inputName,
    changeName
  } from "../../stores/person/setMyPersonNameForm.js";
  import { HeaderNav } from "../../components/common";

  onMount(() => {
    inputName.set("");
    error.set("");
  });
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">Masukan Nama</span>
  </h3>
  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={changeName} class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$user.mobile ? $user.mobile.replace('+628', '08') : ''}
              autocomplete="off"
              id="mobile"
              type="tel"
              class="validate"
              disabled={true} />
            <label class="active" for="mobile">No HP</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputName}
              autocomplete="off"
              id="name"
              type="text"
              class="validate" />
            <label class="active" for="name">Nama Lengkap Tanpa Gelar</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 btn-block">
              Simpan Nama
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <span
              on:click={logout}
              class="right red-text text-darken-4 cursor-pointer">
              Kembali ke Login
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
