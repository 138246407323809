<script>
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let defaultLimit = 10;
  export let defaultPage = 1;
  export let totalItem = 0;

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  $: queryParams = queryString.parse(location.search);
  $: limit = queryParams.limit ? Number(queryParams.limit) : defaultLimit;
  $: page = queryParams.page ? Number(queryParams.page) : defaultPage;

  $: totalPage =
    totalItem && Math.ceil(totalItem / limit) > 1
      ? Math.ceil(totalItem / limit)
      : 1;
  $: pageOptions = new Array(totalPage);

  $: pageTimesLimit = page * limit;
  $: startItem = pageTimesLimit - limit + 1;
  $: endItem = pageTimesLimit < totalItem ? pageTimesLimit : totalItem;

  $: validPrev = page - 1 > 0;
  $: validNext = page + 1 <= totalPage;

  let pageBtnArr = [];
  $: {
    if (page && totalPage) {
      pageBtnArr = pagination(page, totalPage);
    }
  }

  function changePage(v) {
    const newQueryParams = queryParams;
    newQueryParams.page = v;
    if (newQueryParams.page === String(defaultPage)) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);

    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
  }

  function pagination(c, l) {
    // Fungsi Helper Pembuat array Pagination
    const current = c ? c : 1;
    const firstPage = 1;
    const lastPage = l > 0 ? l : 1;
    const range = [];
    // delta merupakan jarak antar tombol
    const delta = 2;
    // maxbutton harus bernilai ganjil
    const maxButton = 5;
    let left = current - delta > 1 ? current - delta : 1;
    let right = lastPage;
    if (current + delta < lastPage) {
      right = current + delta;
    }
    if (right < maxButton) {
      right = maxButton;
    }
    if (left + current > maxButton) {
      left = right - maxButton + 1;
    }

    if (lastPage < maxButton) {
      // jika page length < 5
      right = lastPage;
      left = 1;
    }

    for (let i = left; i <= right; i++) {
      range.push(i);
    }
    if (range[0] !== firstPage) {
      range[0] = "...";
    }
    if (range[range.length - 1] !== lastPage) {
      range[range.length - 1] = "...";
    }
    return range;
  }
</script>

<style>
  .page-box {
    display: flex;
    align-items: center;
  }
  select {
    display: block;
    width: 53px;
    height: 35px;
  }
</style>

<div class="page-box m-v-2">
  <select
    on:change={e => {
      changePage(e.target.value);
    }}
    value={page}>
    {#each pageOptions as v, idx}
      <option value={idx + 1}>{idx + 1}</option>
    {/each}

  </select>
  <div class="m-l-8">
    <button
      on:click|preventDefault={() => {
        if (validPrev) {
          changePage(page - 1);
        }
      }}
      disabled={!validPrev}
      type="button"
      class="btn-small white black-text">
      <i class="fa fa-angle-left" />
    </button>
    {#each pageBtnArr as p, idx}
      <button
        on:click|preventDefault={() => {
          if (idx === 0 && p === '...') {
            return changePage(pageBtnArr[idx + 1] - 1);
          }
          if (idx === pageBtnArr.length - 1 && p === '...') {
            return changePage(pageBtnArr[pageBtnArr.length - 2] + 1);
          }
          if (p !== page) {
            return changePage(p);
          }
        }}
        disabled={p === page}
        type="button"
        class="btn-small white black-text">
        {p}
      </button>
    {/each}
    <button
      on:click|preventDefault={() => {
        if (validNext) {
          changePage(page + 1);
        }
      }}
      disabled={!validNext}
      type="button"
      class="btn-small white black-text">
      <i class="fa fa-angle-right" />
    </button>
  </div>
</div>
