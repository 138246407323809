<script>
  import { onMount, afterUpdate, tick } from "svelte";
  import AdvancedSearchInput from "./AdvancedSearchInput.svelte";
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let advancedSearchConfig = {
    name: {
      key: "name",
      name: "Nama",
      type: "text",
      value: ""
    },
    age: {
      key: "age",
      name: "Umur",
      type: "number",
      value: ""
    },
    sex: {
      key: "sex",
      name: "Jenis Kelamin",
      type: "select",
      value: "",
      options: []
    },
    total: {
      key: "total",
      name: "Total",
      type: "numberCompare",
      value: ""
    }
  };
  let advancedSearchConfigKeys = Object.keys(advancedSearchConfig);
  let activeAdvencedSearchKeys = [];
  let activeSelect = JSON.parse(JSON.stringify(advancedSearchConfigKeys));

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  let queryParams = queryString.parse(location.search);
  let oldQueryParams = {};

  function HandleParamsToValue() {
    queryParams = queryString.parse(location.search);
    if (JSON.stringify(queryParams) !== JSON.stringify(oldQueryParams)) {
      activeAdvencedSearchKeys = [];
      activeSelect = JSON.parse(JSON.stringify(advancedSearchConfigKeys));
      oldQueryParams = queryParams;
      if (Object.keys(queryParams).length > 0) {
        Object.keys(queryParams).forEach(key => {
          if (advancedSearchConfig[key]) {
            const item = advancedSearchConfig[key];
            item.value = queryParams[key];
            activeAdvencedSearchKeys.push(key);
            activeAdvencedSearchKeys = activeAdvencedSearchKeys.sort();
            activeSelect.splice(activeSelect.indexOf(key), 1);
            activeSelect = activeSelect.sort();
          }
        });
      }
    }
  }

  let error = "";

  function selectKey(e) {
    const selectedKey = e.target.value;

    if (selectedKey === "") {
      return;
    }
    if (activeAdvencedSearchKeys.indexOf(selectedKey) > -1) {
      return;
    }
    activeAdvencedSearchKeys.push(selectedKey);
    activeAdvencedSearchKeys = activeAdvencedSearchKeys.sort();
    activeSelect.splice(activeSelect.indexOf(selectedKey), 1);
    activeSelect = activeSelect.sort();
    e.target.value = "";
  }

  function removeKey(selectedKey) {
    if (activeAdvencedSearchKeys.indexOf(selectedKey) < 0) {
      return;
    }
    activeSelect.push(selectedKey);
    activeSelect = activeSelect.sort();
    activeAdvencedSearchKeys.splice(
      activeAdvencedSearchKeys.indexOf(selectedKey),
      1
    );
    activeAdvencedSearchKeys = activeAdvencedSearchKeys.sort();

    advancedSearchConfig[selectedKey].value = "";
    advancedSearchConfig = advancedSearchConfig;
    advancedSearchConfig = advancedSearchConfig;
    setSearch();
  }

  async function setSearch() {
    error = "";
    const newQueryParams = queryParams;

    advancedSearchConfigKeys.forEach(key => {
      const item = advancedSearchConfig[key];
      if (item.value) {
        newQueryParams[key] = item.value;
      } else {
        if (newQueryParams[key]) {
          delete newQueryParams[key];
        }
      }
    });

    if (newQueryParams.page) {
      delete newQueryParams.page;
    }

    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
    await tick();
    onChange(newQueryParams);
  }

  onMount(() => {
    oldQueryParams = {};
    HandleParamsToValue();
  });

  afterUpdate(() => {
    HandleParamsToValue();
  });
</script>

<style>
  .group {
    flex: 1;
    align-items: center;
  }
  select {
    display: inline-block;
    width: 247px;
    height: 35px;
  }
</style>

<form on:submit|preventDefault={setSearch} class="m-v-2">
  <div class="font-20 m-v-2">
    <b>Filter</b>
  </div>
  {#each activeAdvencedSearchKeys as key}
    {#if advancedSearchConfig[key]}
      <div class="group m-v-2">
        <select value={key} disabled>
          <option value={key}>{advancedSearchConfig[key].name}</option>
        </select>
        <AdvancedSearchInput
          defaultValue={advancedSearchConfig[key].value}
          type={advancedSearchConfig[key].type}
          selectOptions={advancedSearchConfig[key].options}
          onChange={v => {
            advancedSearchConfig[key].value = v;
          }} />
        <i
          on:click|preventDefault={() => {
            removeKey(key);
          }}
          class="fa fa-times fa-2x cursor-pointer" />
      </div>
    {/if}
  {/each}
  {#if activeSelect.length > 0}
    <div class="group m-v-2">
      <select value={''} on:change={selectKey}>
        <option value={''}>Silahkan Pilih</option>
        {#each activeSelect as key}
          {#if advancedSearchConfig[key]}
            <option value={key}>{advancedSearchConfig[key].name}</option>
          {/if}
        {/each}
      </select>
    </div>
  {/if}
  <button type="submit" class="btn-small white black-text">Filter</button>
</form>
