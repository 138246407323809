<script>
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let limitOptions = [10, 20, 50, 100];
  export let defaultLimit = 10;
  export let defaultPage = 1;
  export let totalItem = 0;

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  $: queryParams = queryString.parse(location.search);
  $: limit = queryParams.limit ? Number(queryParams.limit) : defaultLimit;
  $: page = queryParams.page ? Number(queryParams.page) : defaultPage;

  $: pageTimesLimit = page * limit;
  $: startItem = pageTimesLimit - limit + 1;
  $: endItem = pageTimesLimit < totalItem ? pageTimesLimit : totalItem;

  function changeLimit(v) {
    const newQueryParams = queryParams;
    newQueryParams.limit = v;
    if (newQueryParams.limit === String(defaultLimit)) {
      delete newQueryParams.limit;
    }
    if (newQueryParams.page) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);
    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
  }
</script>

<style>
  .limit-box {
    display: flex;
    align-items: center;
  }
  select {
    display: block;
    width: 53px;
    height: 35px;
  }
</style>

<div class="limit-box m-v-2">
  <select
    on:change={e => {
      changeLimit(e.target.value);
    }}
    value={limit}>
    {#each limitOptions as opt}
      <option value={opt}>{opt}</option>
    {/each}

  </select>
  <div class="m-l-8">
    Menampilkan {startItem}-{endItem} dari total {totalItem}
  </div>
</div>
