<style>
  :global(.loader-block-item) {
    background-image: url(/loader.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    position: fixed !important;
    z-index: 3000 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    cursor: wait !important;
    overflow: hidden !important;
  }
  :global(.container) {
    max-width: 3840px;
    width: 93% !important;
  }

  @media only screen and (min-width: 601px) {
    :global(.container) {
      width: 95% !important;
    }
  }

  @media only screen and (min-width: 993px) {
    :global(.container) {
      width: 97% !important;
    }
  }

  :global(.root-container) {
    background-color: #fafafa !important;
    margin-bottom: 34px;
  }

  :global(.full-center-container) {
    min-height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  :global(.cursor-pointer) {
    cursor: pointer;
  }
  :global(.cursor-block) {
    cursor: not-allowed;
  }
  :global(.full-width) {
    width: 100%;
  }
  /* :global(.label) {
    margin-right: 14px;
    display: inline-block;

    min-width: 3rem;
    padding: 0 6px;
    text-align: center;
    line-height: 22px;
    height: 22px;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    font-weight: 300;
    font-size: 0.8rem;
    color: #fff;
    background-color: #26a69a;
    border-radius: 2px;
  } */

  :global(.button-circle-panel) {
    cursor: pointer;
    width: 60px;
    height: 60px;
    -webkit-transition: -webkit-box-shadow 0.25s;
    transition: -webkit-box-shadow 0.25s;
    transition: box-shadow 0.25s;
    transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  :global(.button-circle-panel:before) {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  :global(.button-circle-panel:after) {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  :global(.img-circle) {
    width: 42px;
    height: 42px;
    overflow: hidden;
    right: 15px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    border-style: none;
  }
  :global(.img-circle-large) {
    width: 100px;
    height: 100px;
    overflow: hidden;
    right: 15px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    border-style: none;
  }
  :global(.img-width) {
    max-width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  :global(.text-uppercase) {
    text-transform: uppercase;
  }

  :global(.font-2) {
    font-size: 2px !important;
    line-height: 6px !important;
  }
  :global(.font-4) {
    font-size: 4px !important;
    line-height: 8px !important;
  }
  :global(.font-6) {
    font-size: 6px !important;
    line-height: 10px !important;
  }
  :global(.font-8) {
    font-size: 8px !important;
    line-height: 12px !important;
  }
  :global(.font-10) {
    font-size: 10px !important;
    line-height: 14px !important;
  }
  :global(.font-12) {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  :global(.font-14) {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  :global(.font-16) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  :global(.font-18) {
    font-size: 18px !important;
    line-height: 22px !important;
  }
  :global(.font-20) {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  :global(.font-22) {
    font-size: 22px !important;
    line-height: 26px !important;
  }
  :global(.font-24) {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  :global(.font-28) {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  :global(.font-30) {
    font-size: 30px !important;
    line-height: 34px !important;
  }
  :global(.font-100) {
    font-weight: 100 !important;
  }
  :global(.font-200) {
    font-weight: 200 !important;
  }
  :global(.font-300) {
    font-weight: 300 !important;
  }
  :global(.font-400) {
    font-weight: 400 !important;
  }
  :global(.font-500) {
    font-weight: 500 !important;
  }
  :global(.font-600) {
    font-weight: 600 !important;
  }

  :global(.m-0) {
    margin: 0px !important;
  }
  :global(.m-2) {
    margin: 2px !important;
  }
  :global(.m-4) {
    margin: 4px !important;
  }
  :global(.m-6) {
    margin: 6px !important;
  }
  :global(.m-8) {
    margin: 8px !important;
  }
  :global(.m-10) {
    margin: 10px !important;
  }
  :global(.m-12) {
    margin: 12px !important;
  }
  :global(.m-14) {
    margin: 14px !important;
  }
  :global(.m-16) {
    margin: 16px !important;
  }
  :global(.m-18) {
    margin: 18px !important;
  }
  :global(.m-20) {
    margin: 20px !important;
  }
  :global(.m-22) {
    margin: 22px !important;
  }
  :global(.m-24) {
    margin: 24px !important;
  }
  :global(.m-26) {
    margin: 26px !important;
  }
  :global(.m-28) {
    margin: 28px !important;
  }
  :global(.m-30) {
    margin: 30px !important;
  }

  :global(.m-v-0) {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  :global(.m-v-2) {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  :global(.m-v-4) {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  :global(.m-v-6) {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  :global(.m-v-8) {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  :global(.m-v-10) {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  :global(.m-v-12) {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  :global(.m-v-14) {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  :global(.m-v-16) {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  :global(.m-v-18) {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  :global(.m-v-20) {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  :global(.m-v-22) {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  :global(.m-v-24) {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  :global(.m-v-26) {
    margin-top: 26px !important;
    margin-bottom: 26px !important;
  }
  :global(.m-v-28) {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  :global(.m-v-30) {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  :global(.m-h-0) {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  :global(.m-h-2) {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  :global(.m-h-4) {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  :global(.m-h-6) {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  :global(.m-h-8) {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  :global(.m-h-10) {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  :global(.m-h-12) {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  :global(.m-h-14) {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  :global(.m-h-16) {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  :global(.m-h-18) {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  :global(.m-h-20) {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  :global(.m-h-22) {
    margin-right: 22px !important;
    margin-left: 22px !important;
  }
  :global(.m-h-24) {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  :global(.m-h-26) {
    margin-right: 26px !important;
    margin-left: 26px !important;
  }
  :global(.m-h-28) {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  :global(.m-h-30) {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  :global(.m-t-0) {
    margin-top: 0px !important;
  }
  :global(.m-t-2) {
    margin-top: 2px !important;
  }
  :global(.m-t-4) {
    margin-top: 4px !important;
  }
  :global(.m-t-6) {
    margin-top: 6px !important;
  }
  :global(.m-t-8) {
    margin-top: 8px !important;
  }
  :global(.m-t-10) {
    margin-top: 10px !important;
  }
  :global(.m-t-12) {
    margin-top: 12px !important;
  }
  :global(.m-t-14) {
    margin-top: 14px !important;
  }
  :global(.m-t-16) {
    margin-top: 16px !important;
  }
  :global(.m-t-18) {
    margin-top: 18px !important;
  }
  :global(.m-t-20) {
    margin-top: 20px !important;
  }
  :global(.m-t-22) {
    margin-top: 22px !important;
  }
  :global(.m-t-24) {
    margin-top: 24px !important;
  }
  :global(.m-t-26) {
    margin-top: 26px !important;
  }
  :global(.m-t-28) {
    margin-top: 28px !important;
  }
  :global(.m-t-30) {
    margin-top: 30px !important;
  }

  :global(.m-r-0) {
    margin-right: 0px !important;
  }
  :global(.m-r-2) {
    margin-right: 2px !important;
  }
  :global(.m-r-4) {
    margin-right: 4px !important;
  }
  :global(.m-r-6) {
    margin-right: 6px !important;
  }
  :global(.m-r-8) {
    margin-right: 8px !important;
  }
  :global(.m-r-10) {
    margin-right: 10px !important;
  }
  :global(.m-r-12) {
    margin-right: 12px !important;
  }
  :global(.m-r-14) {
    margin-right: 14px !important;
  }
  :global(.m-r-16) {
    margin-right: 16px !important;
  }
  :global(.m-r-18) {
    margin-right: 18px !important;
  }
  :global(.m-r-20) {
    margin-right: 20px !important;
  }
  :global(.m-r-22) {
    margin-right: 22px !important;
  }
  :global(.m-r-24) {
    margin-right: 24px !important;
  }
  :global(.m-r-26) {
    margin-right: 26px !important;
  }
  :global(.m-r-28) {
    margin-right: 28px !important;
  }
  :global(.m-r-30) {
    margin-right: 30px !important;
  }

  :global(.m-b-0) {
    margin-bottom: 0px !important;
  }
  :global(.m-b-2) {
    margin-bottom: 2px !important;
  }
  :global(.m-b-4) {
    margin-bottom: 4px !important;
  }
  :global(.m-b-6) {
    margin-bottom: 6px !important;
  }
  :global(.m-b-8) {
    margin-bottom: 8px !important;
  }
  :global(.m-b-10) {
    margin-bottom: 10px !important;
  }
  :global(.m-b-12) {
    margin-bottom: 12px !important;
  }
  :global(.m-b-14) {
    margin-bottom: 14px !important;
  }
  :global(.m-b-16) {
    margin-bottom: 16px !important;
  }
  :global(.m-b-18) {
    margin-bottom: 18px !important;
  }
  :global(.m-b-20) {
    margin-bottom: 20px !important;
  }
  :global(.m-b-22) {
    margin-bottom: 22px !important;
  }
  :global(.m-b-24) {
    margin-bottom: 24px !important;
  }
  :global(.m-b-26) {
    margin-bottom: 26px !important;
  }
  :global(.m-b-28) {
    margin-bottom: 28px !important;
  }
  :global(.m-b-30) {
    margin-bottom: 30px !important;
  }

  :global(.m-l-0) {
    margin-left: 0px !important;
  }
  :global(.m-l-2) {
    margin-left: 2px !important;
  }
  :global(.m-l-4) {
    margin-left: 4px !important;
  }
  :global(.m-l-6) {
    margin-left: 6px !important;
  }
  :global(.m-l-8) {
    margin-left: 8px !important;
  }
  :global(.m-l-10) {
    margin-left: 10px !important;
  }
  :global(.m-l-12) {
    margin-left: 12px !important;
  }
  :global(.m-l-14) {
    margin-left: 14px !important;
  }
  :global(.m-l-16) {
    margin-left: 16px !important;
  }
  :global(.m-l-18) {
    margin-left: 18px !important;
  }
  :global(.m-l-20) {
    margin-left: 20px !important;
  }
  :global(.m-l-22) {
    margin-left: 22px !important;
  }
  :global(.m-l-24) {
    margin-left: 24px !important;
  }
  :global(.m-l-26) {
    margin-left: 26px !important;
  }
  :global(.m-l-28) {
    margin-left: 28px !important;
  }
  :global(.m-l-30) {
    margin-left: 30px !important;
  }

  :global(.p-0) {
    padding: 0px !important;
  }
  :global(.p-2) {
    padding: 2px !important;
  }
  :global(.p-4) {
    padding: 4px !important;
  }
  :global(.p-6) {
    padding: 6px !important;
  }
  :global(.p-8) {
    padding: 8px !important;
  }
  :global(.p-10) {
    padding: 10px !important;
  }
  :global(.p-12) {
    padding: 12px !important;
  }
  :global(.p-14) {
    padding: 14px !important;
  }
  :global(.p-16) {
    padding: 16px !important;
  }
  :global(.p-18) {
    padding: 18px !important;
  }
  :global(.p-20) {
    padding: 20px !important;
  }
  :global(.p-22) {
    padding: 22px !important;
  }
  :global(.p-24) {
    padding: 24px !important;
  }
  :global(.p-26) {
    padding: 26px !important;
  }
  :global(.p-28) {
    padding: 28px !important;
  }
  :global(.p-30) {
    padding: 30px !important;
  }

  :global(.p-v-0) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  :global(.p-v-2) {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  :global(.p-v-4) {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  :global(.p-v-6) {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  :global(.p-v-8) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  :global(.p-v-10) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  :global(.p-v-12) {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  :global(.p-v-14) {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  :global(.p-v-16) {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  :global(.p-v-18) {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  :global(.p-v-20) {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  :global(.p-v-22) {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
  :global(.p-v-24) {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  :global(.p-v-26) {
    padding-top: 26px !important;
    padding-bottom: 26px !important;
  }
  :global(.p-v-28) {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  :global(.p-v-30) {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  :global(.p-h-0) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  :global(.p-h-2) {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  :global(.p-h-4) {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  :global(.p-h-6) {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  :global(.p-h-8) {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  :global(.p-h-10) {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  :global(.p-h-12) {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  :global(.p-h-14) {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  :global(.p-h-16) {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  :global(.p-h-18) {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  :global(.p-h-20) {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  :global(.p-h-22) {
    padding-right: 22px !important;
    padding-left: 22px !important;
  }
  :global(.p-h-24) {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  :global(.p-h-26) {
    padding-right: 26px !important;
    padding-left: 26px !important;
  }
  :global(.p-h-28) {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  :global(.p-h-30) {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  :global(.p-t-0) {
    padding-top: 0px !important;
  }
  :global(.p-t-2) {
    padding-top: 2px !important;
  }
  :global(.p-t-4) {
    padding-top: 4px !important;
  }
  :global(.p-t-6) {
    padding-top: 6px !important;
  }
  :global(.p-t-8) {
    padding-top: 8px !important;
  }
  :global(.p-t-10) {
    padding-top: 10px !important;
  }
  :global(.p-t-12) {
    padding-top: 12px !important;
  }
  :global(.p-t-14) {
    padding-top: 14px !important;
  }
  :global(.p-t-16) {
    padding-top: 16px !important;
  }
  :global(.p-t-18) {
    padding-top: 18px !important;
  }
  :global(.p-t-20) {
    padding-top: 20px !important;
  }
  :global(.p-t-22) {
    padding-top: 22px !important;
  }
  :global(.p-t-24) {
    padding-top: 24px !important;
  }
  :global(.p-t-26) {
    padding-top: 26px !important;
  }
  :global(.p-t-28) {
    padding-top: 28px !important;
  }
  :global(.p-t-30) {
    padding-top: 30px !important;
  }

  :global(.p-r-0) {
    padding-right: 0px !important;
  }
  :global(.p-r-2) {
    padding-right: 2px !important;
  }
  :global(.p-r-4) {
    padding-right: 4px !important;
  }
  :global(.p-r-6) {
    padding-right: 6px !important;
  }
  :global(.p-r-8) {
    padding-right: 8px !important;
  }
  :global(.p-r-10) {
    padding-right: 10px !important;
  }
  :global(.p-r-12) {
    padding-right: 12px !important;
  }
  :global(.p-r-14) {
    padding-right: 14px !important;
  }
  :global(.p-r-16) {
    padding-right: 16px !important;
  }
  :global(.p-r-18) {
    padding-right: 18px !important;
  }
  :global(.p-r-20) {
    padding-right: 20px !important;
  }
  :global(.p-r-22) {
    padding-right: 22px !important;
  }
  :global(.p-r-24) {
    padding-right: 24px !important;
  }
  :global(.p-r-26) {
    padding-right: 26px !important;
  }
  :global(.p-r-28) {
    padding-right: 28px !important;
  }
  :global(.p-r-30) {
    padding-right: 30px !important;
  }

  :global(.p-b-0) {
    padding-bottom: 0px !important;
  }
  :global(.p-b-2) {
    padding-bottom: 2px !important;
  }
  :global(.p-b-4) {
    padding-bottom: 4px !important;
  }
  :global(.p-b-6) {
    padding-bottom: 6px !important;
  }
  :global(.p-b-8) {
    padding-bottom: 8px !important;
  }
  :global(.p-b-10) {
    padding-bottom: 10px !important;
  }
  :global(.p-b-12) {
    padding-bottom: 12px !important;
  }
  :global(.p-b-14) {
    padding-bottom: 14px !important;
  }
  :global(.p-b-16) {
    padding-bottom: 16px !important;
  }
  :global(.p-b-18) {
    padding-bottom: 18px !important;
  }
  :global(.p-b-20) {
    padding-bottom: 20px !important;
  }
  :global(.p-b-22) {
    padding-bottom: 22px !important;
  }
  :global(.p-b-24) {
    padding-bottom: 24px !important;
  }
  :global(.p-b-26) {
    padding-bottom: 26px !important;
  }
  :global(.p-b-28) {
    padding-bottom: 28px !important;
  }
  :global(.p-b-30) {
    padding-bottom: 30px !important;
  }

  :global(.p-l-0) {
    padding-left: 0px !important;
  }
  :global(.p-l-2) {
    padding-left: 2px !important;
  }
  :global(.p-l-4) {
    padding-left: 4px !important;
  }
  :global(.p-l-6) {
    padding-left: 6px !important;
  }
  :global(.p-l-8) {
    padding-left: 8px !important;
  }
  :global(.p-l-10) {
    padding-left: 10px !important;
  }
  :global(.p-l-12) {
    padding-left: 12px !important;
  }
  :global(.p-l-14) {
    padding-left: 14px !important;
  }
  :global(.p-l-16) {
    padding-left: 16px !important;
  }
  :global(.p-l-18) {
    padding-left: 18px !important;
  }
  :global(.p-l-20) {
    padding-left: 20px !important;
  }
  :global(.p-l-22) {
    padding-left: 22px !important;
  }
  :global(.p-l-24) {
    padding-left: 24px !important;
  }
  :global(.p-l-26) {
    padding-left: 26px !important;
  }
  :global(.p-l-28) {
    padding-left: 28px !important;
  }
  :global(.p-l-30) {
    padding-left: 30px !important;
  }

  /* Modal Global CSS START */
  :global(.modal-frame) {
    overflow: scroll;
    z-index: 2002;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  :global(.modal-container) {
    margin-top: 4%;
    margin-left: 8%;
    margin-right: 8%;
  }
  :global(.modal-container-full) {
    z-index: 2001;
    position: sticky;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    height: 100%;
  }

  :global(.modal-container-full > .card) {
    margin: 0px;
    height: 100%;
  }

  :global(.modal-container-full > .card > .card-navigation) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 56px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  :global(.modal-container-half) {
    z-index: 2001;
    position: absolute;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    height: 50%;
    width: 100%;
    bottom: 0px;
  }

  :global(.modal-container-half > .card) {
    background-color: #fafafa !important;
    margin-bottom: 0px;
    height: 100%;
  }

  :global(.modal-container-half > .card > .card-navigation) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 56px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  /* Modal END */

  /* Card */
  :global(.card-title-box) {
    font-size: 14px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
  }

  :global(.list-unstyled) {
    list-style-type: none;
  }

  /* â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€“
  Tree core styles START
*/
  :global(.tree) {
    margin: 1em;
  }

  :global(.tree .tree-icon) {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  :global(.tree .tree-icon ~ ul) {
    display: none;
  }

  :global(.tree .tree-icon.active ~ ul) {
    display: block;
  }

  /* â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€“
  Tree rows
*/
  :global(.tree li) {
    line-height: 1.2;
    position: relative;
    padding: 0 0 1em 1em;
  }

  :global(.tree ul li) {
    padding: 1em 0 0 1em;
  }

  :global(.tree > li:last-child) {
    padding-bottom: 0;
  }

  /* â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€“
  Tree labels
*/
  :global(.tree_label) {
    position: relative;
    display: inline-block;
    background: #fff;
  }

  /* â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€“
  Tree expanded icon
*/
  :global(label.tree_label:before) {
    left: -0.3em;
    background: #000;
    color: #fff;
    position: relative;
    z-index: 1;
    float: left;
    margin: 0 1em 0 -2em;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    content: "+";
    text-align: center;
    line-height: 0.9em;
  }

  :global(.active ~ label.tree_label:before) {
    content: "-";
  }
  :global(.no-icon ~ label.tree_label:before) {
    content: "";
  }

  /* â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€”â€“
  Tree branches
*/
  :global(.tree li:before) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.46em;
    display: block;
    width: 0;
    border-left: 1px solid #777;
    content: "";
  }

  :global(.tree_label:after) {
    position: absolute;
    top: 0;
    left: -1.5em;
    display: block;
    height: 0.5em;
    width: 1em;
    border-bottom: 1px solid #777;
    border-left: none;
    border-radius: 0 0 0 0.3em;
    content: "";
  }

  :global(label.tree_label:after) {
    border-bottom: 0;
  }

  :global(.active ~ label.tree_label:after) {
    border-radius: 0 0.3em 0 0;
    border-top: 1px solid #777;
    border-right: 1px solid #777;
    border-bottom: 0;
    border-left: 0;
    bottom: 0;
    top: 0.5em;
    height: auto;
  }

  :global(.active ~ label.tree_label.no-child:after) {
    border-right: none;
  }

  :global(.tree li:last-child:before) {
    height: 1em;
    bottom: auto;
  }

  :global(.tree > li:last-child:before) {
    display: none;
  }

  :global(.tree_custom) {
    display: block;
    background: #eee;
    padding: 1em;
    border-radius: 0.3em;
  }

  :global(.tree .button-expand) {
    position: absolute;
    z-index: 10;
    left: -13px;
    top: 12px;
    color: red;
    font-size: 26px;
  }

  :global(.tree .button-parent) {
    left: -11px;
    top: -7px;
  }

  /* End Of Tree */
</style>
