<script>
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let sortOptions = [
    {
      key: "name",
      name: "Nama"
    },
    {
      key: "createdAt",
      name: "Dibuat"
    }
  ];
  export let defaultSort = "name";
  export let defaultSortDir = "asc";

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  $: queryParams = queryString.parse(location.search);
  $: sort = queryParams.sort ? queryParams.sort : defaultSort;
  $: sortDir = queryParams.sortDir ? queryParams.sortDir : defaultSortDir;

  function changeSort(v) {
    const newQueryParams = queryParams;
    newQueryParams.sort = v;
    if (newQueryParams.sort === defaultSort && sortDir === defaultSortDir) {
      delete newQueryParams.sort;
    }
    onChange(newQueryParams);
    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
  }
  function changeSortDir(v) {
    const newQueryParams = queryParams;
    newQueryParams.sortDir = v;
    if (newQueryParams.sortDir === defaultSortDir) {
      delete newQueryParams.sortDir;
      if (sort === defaultSort) {
        delete newQueryParams.sort;
      }
    } else if (sort === defaultSort) {
      newQueryParams.sort = sort;
    }
    onChange(newQueryParams);
    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
  }
</script>

<style>
  .sort-box {
    display: flex;
    align-items: center;
  }
  select {
    display: block;
    width: 250px;
    height: 35px;
  }
</style>

<div class="sort-box m-v-2">
  <slot name="sort" />
  <button
    on:click|preventDefault={() => {
      if (sortDir === 'asc') {
        return changeSortDir('desc');
      }
      return changeSortDir('asc');
    }}
    type="button"
    class="btn-small white black-text">
    {#if sortDir === 'asc'}
      <i class="fa fa-sort-numeric-asc" />
    {:else}
      <i class="fa fa-sort-numeric-desc" />
    {/if}
  </button>
  <select
    on:change={e => {
      changeSort(e.target.value);
    }}
    value={sort}>
    {#each sortOptions as opt}
      <option value={opt.key}>{opt.name}</option>
    {/each}
  </select>
</div>
