<script>
  import Search from "./Search.svelte";
  import Sort from "./Sort.svelte";

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };
  export let location;

  export let defaultSearch = "";
  export let minLength = 3;

  export let sortOptions = [
    {
      key: "name",
      name: "Nama"
    },
    {
      key: "createdAt",
      name: "Dibuat"
    }
  ];
  export let defaultSort = "name";
  export let defaultSortDir = "asc";
</script>

<style>
  @media only screen and (min-width: 601px) {
    .pagination-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>

<div class="pagination-wrapper">
  <Search {location} {onChange} {defaultSearch} {minLength}>
    <span slot="search">
      <slot name="search" />
    </span>
  </Search>
  <Sort {location} {onChange} {sortOptions} {defaultSort} {defaultSortDir}>
    <span slot="sort">
      <slot name="sort" />
    </span>
  </Sort>
</div>
