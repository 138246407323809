<script>
  import PaginationShow from "./PaginationShow.svelte";
  import PaginationPage from "./PaginationPage.svelte";

  export let totalItem = 0;
  export let limitOptions = [10, 20, 50, 100];
  export let defaultLimit = 10;
  export let defaultPage = 1;

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
</script>

<style>
  @media only screen and (min-width: 601px) {
    .pagination-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>

<div class="pagination-wrapper">
  <PaginationShow
    {location}
    {onChange}
    {totalItem}
    {limitOptions}
    {defaultLimit}
    {defaultPage} />
  <PaginationPage
    {location}
    {onChange}
    {totalItem}
    {defaultLimit}
    {defaultPage} />
</div>
