<script>

</script>

<style>
  .footer-position {
    position: fixed;
    z-index: -20;
    bottom: 0px;
    width: 100%;
    margin: 8px 14px;
  }
  .footer-flex {
    display: flex;
    align-items: center;
  }
</style>

<div class="footer-position">
  <div class="footer-flex">
    <div>
      e-Kinerja V3
      <a
        class="red-text text-darken-4"
        href="https://ekin.sulutprov.go.id/"
        target="_blank">
        Badan Kepegawaian Daerah
      </a>
      {`@2019`}
    </div>
  </div>
</div>
