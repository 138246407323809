<script>
  import { onMount, afterUpdate, tick } from "svelte";
  import AdvancedSearchInput from "./AdvancedSearchInput.svelte";
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let advancedSearchConfig = {
    date: {
      key: "date",
      name: "Tanggal",
      type: "date",
      value: ""
    },
    month: {
      key: "month",
      name: "Bulan",
      type: "month",
      value: ""
    }
  };
  export let defaultSelectedActive = "";
  let advancedSearchConfigKeys = Object.keys(advancedSearchConfig);
  let activeSelect = {};

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  let queryParams = queryString.parse(location.search);
  let oldQueryParams = {};

  function HandleParamsToValue() {
    queryParams = queryString.parse(location.search);
    if (JSON.stringify(queryParams) !== JSON.stringify(oldQueryParams)) {
      activeSelect = {};

      oldQueryParams = queryParams;
      if (Object.keys(queryParams).length > 0) {
        Object.keys(queryParams).forEach(key => {
          if (advancedSearchConfig[key]) {
            const item = advancedSearchConfig[key];
            item.value = queryParams[key];
            activeSelect = item;
          }
        });
      }
    }
    if (defaultSelectedActive && advancedSearchConfig[defaultSelectedActive]) {
      activeSelect = advancedSearchConfig[defaultSelectedActive];
    }
  }

  let error = "";

  function selectKey(e) {
    const selectedKey = e.target.value;

    if (selectedKey === "") {
      return;
    }
    activeSelect = advancedSearchConfig[selectedKey];
  }

  async function removeKey(selectedKey) {
    activeSelect = {};
    const newQueryParams = queryParams;
    advancedSearchConfigKeys.forEach(key => {
      delete newQueryParams[key];
    });
    if (newQueryParams.page) {
      delete newQueryParams.page;
    }

    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
    await tick();
    onChange(newQueryParams);
  }

  async function setSearch() {
    error = "";
    const newQueryParams = queryParams;

    advancedSearchConfigKeys.forEach(key => {
      delete newQueryParams[key];
    });

    if (newQueryParams.page) {
      delete newQueryParams.page;
    }
    if (activeSelect && activeSelect.key) {
      newQueryParams[activeSelect.key] = activeSelect.value;
    }
    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
    await tick();
    onChange(newQueryParams);
  }

  onMount(() => {
    oldQueryParams = {};
    HandleParamsToValue();
  });

  afterUpdate(() => {
    HandleParamsToValue();
  });
</script>

<style>
  .group {
    flex: 1;
    align-items: center;
  }
  select {
    display: inline-block;
    width: 247px;
    height: 35px;
  }
  .submit-fa {
    border: none;
  }
</style>

<form on:submit|preventDefault={setSearch} class="m-v-2">
  {#if activeSelect && activeSelect.key}
    <div class="group m-v-2">
      <select value={activeSelect.key} on:change={selectKey}>
        {#each advancedSearchConfigKeys as key}
          {#if advancedSearchConfig[key]}
            <option value={key}>{advancedSearchConfig[key].name}</option>
          {/if}
        {/each}
      </select>
      <AdvancedSearchInput
        defaultValue={advancedSearchConfig[activeSelect.key].value}
        type={advancedSearchConfig[activeSelect.key].type}
        selectOptions={advancedSearchConfig[activeSelect.key].options}
        onChange={v => {
          advancedSearchConfig[activeSelect.key].value = v;
        }} />
      <i
        on:click|preventDefault={() => {
          removeKey(activeSelect.key);
        }}
        class="fa fa-times fa-2x cursor-pointer" />
      <button type="submit" class="fa fa-2x fa-search submit-fa blue-text" />
    </div>
  {:else}
    <div class="group m-v-2">
      <select value={''} on:change={selectKey}>
        <option value={''}>Silahkan Pilih</option>
        {#each advancedSearchConfigKeys as key}
          {#if advancedSearchConfig[key]}
            <option value={key}>{advancedSearchConfig[key].name}</option>
          {/if}
        {/each}
      </select>
    </div>
  {/if}

</form>
