<script>
  import { onMount, afterUpdate } from "svelte";
  import queryString from "query-string";
  import { navigate } from "svelte-routing";

  export let defaultSearch = "";
  export let minLength = 3;

  export let onChange = newQueryParams => {
    console.warn(`newQueryParams`, newQueryParams);
  };

  export let location;
  $: queryParams = queryString.parse(location.search);
  $: search = queryParams.search ? queryParams.search : defaultSearch;
  $: searchInput = search;

  let error = "";

  function setSearch(v) {
    error = "";
    if (v && v.length < minLength) {
      error = `Minimal ${minLength} karakter`;
    }
    const newQueryParams = queryParams;
    newQueryParams.search = v;
    if (
      newQueryParams.search === defaultSearch ||
      newQueryParams.search === ""
    ) {
      delete newQueryParams.search;
    }
    if (newQueryParams.page) {
      delete newQueryParams.page;
    }
    onChange(newQueryParams);
    navigate(`${location.pathname}?${queryString.stringify(newQueryParams)}`);
  }
</script>

<style>
  .sort-box {
    display: flex;
    align-items: center;
  }
  input[type="text"] {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
    margin-bottom: 0px;
  }
  input[type="text"] {
    display: inline-block;
    width: 235px;
    height: 23px;
  }
  .input-icon-right {
    position: relative;
  }
  .input-icon-right > i {
    margin: -9px 0px 0px -24px;
    z-index: 4;
    display: inline-block;
    position: absolute;
    width: 16px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
</style>

<form
  on:submit|preventDefault={() => {
    setSearch(searchInput);
  }}
  class="sort-box m-v-2">
  <input bind:value={searchInput} type="text" />
  <div
    on:click|preventDefault={() => {
      searchInput = '';
      setSearch(searchInput);
    }}
    class="input-icon-right">
    <i class="fa fa-times grey-text" />
  </div>
  <button type="submit" class="btn-small white black-text">
    <i class="fa fa-search" />
  </button>
  <slot name="search" />
  <div class="m-l-8 red-text">{error}</div>
</form>
